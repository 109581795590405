/* eslint no-use-before-define: 0 */
import {defineNuxtPlugin} from '#app'
import {createI18n} from 'vue-i18n'

export default defineNuxtPlugin((nuxtApp) => {
  const locales = {}
  const i18n = createI18n({
    locale: 'en',
    legacy: false,
    messages: locales,
    globalInjection: true,
    missing: (locale, key) => {
      return `i18n ` + key
    }
  })
  nuxtApp.vueApp.use(i18n)
})
