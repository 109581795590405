import { rest } from "msw";
import { addBaseUrl, makeResponse } from "./helpers";
import RESPONSE from "./api/faq/faqs.json";
import UNLEASH from "./api/unleash/unleash.json";

export const handlers = [
  rest.post(addBaseUrl("contact/sc-contact-request/"), (req, res, ctx) => {
    return makeResponse({
      res,
      ctx,
      status: 200,
    });
  }),
  rest.post(addBaseUrl("contact/sc-subscription-request/"), (req, res, ctx) => {
    return makeResponse({
      res,
      ctx,
      status: 200,
    });
  }),
  rest.get(addBaseUrl("faq/faqs/"), (req, res, ctx) => {
    return makeResponse({
      res,
      ctx,
      status: 200,
      json: RESPONSE,
    });
  }),
  rest.get("https://sit.shop-circuit.ch/unleash/proxy", (req, res, ctx) => {
    return makeResponse({
      res,
      ctx,
      status: 200,
      json: UNLEASH,
    });
  }),
];
