import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as faqIMkmXQpKKaMeta } from "/app/pages/faq.vue?macro=true";
import { default as forgot_45password1UbT4qDpFeMeta } from "/app/pages/forgot-password.vue?macro=true";
import { default as imprintXQz1X9eYwAMeta } from "/app/pages/imprint.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as pricingpTmAIboEh0Meta } from "/app/pages/pricing.vue?macro=true";
import { default as productLAFqVFqaQCMeta } from "/app/pages/product.vue?macro=true";
import { default as set_45new_45password7f5ymwzqiYMeta } from "/app/pages/set-new-password.vue?macro=true";
import { default as signinUhHmpbjN2pMeta } from "/app/pages/signin.vue?macro=true";
import { default as signupezBbZGXKU6Meta } from "/app/pages/signup.vue?macro=true";
import { default as subscribeuWSXqI2j0lMeta } from "/app/pages/subscribe.vue?macro=true";
import { default as _91token_93nN8tYCEP3uMeta } from "/app/pages/unsubscribe/[token].vue?macro=true";
export default [
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___en",
    path: aboutu8Sb32ihwPMeta?.path ?? "/about",
    children: [],
    meta: aboutu8Sb32ihwPMeta,
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect || undefined,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___de",
    path: aboutu8Sb32ihwPMeta?.path ?? "/de/about",
    children: [],
    meta: aboutu8Sb32ihwPMeta,
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect || undefined,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contactV6oPJjs9RyMeta?.name ?? "contact___en",
    path: contactV6oPJjs9RyMeta?.path ?? "/contact",
    children: [],
    meta: contactV6oPJjs9RyMeta,
    alias: contactV6oPJjs9RyMeta?.alias || [],
    redirect: contactV6oPJjs9RyMeta?.redirect || undefined,
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactV6oPJjs9RyMeta?.name ?? "contact___de",
    path: contactV6oPJjs9RyMeta?.path ?? "/de/contact",
    children: [],
    meta: contactV6oPJjs9RyMeta,
    alias: contactV6oPJjs9RyMeta?.alias || [],
    redirect: contactV6oPJjs9RyMeta?.redirect || undefined,
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: faqIMkmXQpKKaMeta?.name ?? "faq___en",
    path: faqIMkmXQpKKaMeta?.path ?? "/faq",
    children: [],
    meta: faqIMkmXQpKKaMeta,
    alias: faqIMkmXQpKKaMeta?.alias || [],
    redirect: faqIMkmXQpKKaMeta?.redirect || undefined,
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqIMkmXQpKKaMeta?.name ?? "faq___de",
    path: faqIMkmXQpKKaMeta?.path ?? "/de/faq",
    children: [],
    meta: faqIMkmXQpKKaMeta,
    alias: faqIMkmXQpKKaMeta?.alias || [],
    redirect: faqIMkmXQpKKaMeta?.redirect || undefined,
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password1UbT4qDpFeMeta?.name ?? "forgot-password___en",
    path: forgot_45password1UbT4qDpFeMeta?.path ?? "/forgot-password",
    children: [],
    meta: forgot_45password1UbT4qDpFeMeta,
    alias: forgot_45password1UbT4qDpFeMeta?.alias || [],
    redirect: forgot_45password1UbT4qDpFeMeta?.redirect || undefined,
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password1UbT4qDpFeMeta?.name ?? "forgot-password___de",
    path: forgot_45password1UbT4qDpFeMeta?.path ?? "/de/forgot-password",
    children: [],
    meta: forgot_45password1UbT4qDpFeMeta,
    alias: forgot_45password1UbT4qDpFeMeta?.alias || [],
    redirect: forgot_45password1UbT4qDpFeMeta?.redirect || undefined,
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: imprintXQz1X9eYwAMeta?.name ?? "imprint___en",
    path: imprintXQz1X9eYwAMeta?.path ?? "/imprint",
    children: [],
    meta: imprintXQz1X9eYwAMeta,
    alias: imprintXQz1X9eYwAMeta?.alias || [],
    redirect: imprintXQz1X9eYwAMeta?.redirect || undefined,
    component: () => import("/app/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: imprintXQz1X9eYwAMeta?.name ?? "imprint___de",
    path: imprintXQz1X9eYwAMeta?.path ?? "/de/imprint",
    children: [],
    meta: imprintXQz1X9eYwAMeta,
    alias: imprintXQz1X9eYwAMeta?.alias || [],
    redirect: imprintXQz1X9eYwAMeta?.redirect || undefined,
    component: () => import("/app/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    children: [],
    meta: indexN6pT4Un8hYMeta,
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___de",
    path: indexN6pT4Un8hYMeta?.path ?? "/de",
    children: [],
    meta: indexN6pT4Un8hYMeta,
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pricingpTmAIboEh0Meta?.name ?? "pricing___en",
    path: pricingpTmAIboEh0Meta?.path ?? "/pricing",
    children: [],
    meta: pricingpTmAIboEh0Meta,
    alias: pricingpTmAIboEh0Meta?.alias || [],
    redirect: pricingpTmAIboEh0Meta?.redirect || undefined,
    component: () => import("/app/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingpTmAIboEh0Meta?.name ?? "pricing___de",
    path: pricingpTmAIboEh0Meta?.path ?? "/de/pricing",
    children: [],
    meta: pricingpTmAIboEh0Meta,
    alias: pricingpTmAIboEh0Meta?.alias || [],
    redirect: pricingpTmAIboEh0Meta?.redirect || undefined,
    component: () => import("/app/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: productLAFqVFqaQCMeta?.name ?? "product___en",
    path: productLAFqVFqaQCMeta?.path ?? "/product",
    children: [],
    meta: productLAFqVFqaQCMeta,
    alias: productLAFqVFqaQCMeta?.alias || [],
    redirect: productLAFqVFqaQCMeta?.redirect || undefined,
    component: () => import("/app/pages/product.vue").then(m => m.default || m)
  },
  {
    name: productLAFqVFqaQCMeta?.name ?? "product___de",
    path: productLAFqVFqaQCMeta?.path ?? "/de/product",
    children: [],
    meta: productLAFqVFqaQCMeta,
    alias: productLAFqVFqaQCMeta?.alias || [],
    redirect: productLAFqVFqaQCMeta?.redirect || undefined,
    component: () => import("/app/pages/product.vue").then(m => m.default || m)
  },
  {
    name: set_45new_45password7f5ymwzqiYMeta?.name ?? "set-new-password___en",
    path: set_45new_45password7f5ymwzqiYMeta?.path ?? "/set-new-password",
    children: [],
    meta: set_45new_45password7f5ymwzqiYMeta,
    alias: set_45new_45password7f5ymwzqiYMeta?.alias || [],
    redirect: set_45new_45password7f5ymwzqiYMeta?.redirect || undefined,
    component: () => import("/app/pages/set-new-password.vue").then(m => m.default || m)
  },
  {
    name: set_45new_45password7f5ymwzqiYMeta?.name ?? "set-new-password___de",
    path: set_45new_45password7f5ymwzqiYMeta?.path ?? "/de/set-new-password",
    children: [],
    meta: set_45new_45password7f5ymwzqiYMeta,
    alias: set_45new_45password7f5ymwzqiYMeta?.alias || [],
    redirect: set_45new_45password7f5ymwzqiYMeta?.redirect || undefined,
    component: () => import("/app/pages/set-new-password.vue").then(m => m.default || m)
  },
  {
    name: signinUhHmpbjN2pMeta?.name ?? "signin___en",
    path: signinUhHmpbjN2pMeta?.path ?? "/signin",
    children: [],
    meta: signinUhHmpbjN2pMeta,
    alias: signinUhHmpbjN2pMeta?.alias || [],
    redirect: signinUhHmpbjN2pMeta?.redirect || undefined,
    component: () => import("/app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signinUhHmpbjN2pMeta?.name ?? "signin___de",
    path: signinUhHmpbjN2pMeta?.path ?? "/de/signin",
    children: [],
    meta: signinUhHmpbjN2pMeta,
    alias: signinUhHmpbjN2pMeta?.alias || [],
    redirect: signinUhHmpbjN2pMeta?.redirect || undefined,
    component: () => import("/app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signupezBbZGXKU6Meta?.name ?? "signup___en",
    path: signupezBbZGXKU6Meta?.path ?? "/signup",
    children: [],
    meta: signupezBbZGXKU6Meta,
    alias: signupezBbZGXKU6Meta?.alias || [],
    redirect: signupezBbZGXKU6Meta?.redirect || undefined,
    component: () => import("/app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: signupezBbZGXKU6Meta?.name ?? "signup___de",
    path: signupezBbZGXKU6Meta?.path ?? "/de/signup",
    children: [],
    meta: signupezBbZGXKU6Meta,
    alias: signupezBbZGXKU6Meta?.alias || [],
    redirect: signupezBbZGXKU6Meta?.redirect || undefined,
    component: () => import("/app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: subscribeuWSXqI2j0lMeta?.name ?? "subscribe___en",
    path: subscribeuWSXqI2j0lMeta?.path ?? "/subscribe",
    children: [],
    meta: subscribeuWSXqI2j0lMeta,
    alias: subscribeuWSXqI2j0lMeta?.alias || [],
    redirect: subscribeuWSXqI2j0lMeta?.redirect || undefined,
    component: () => import("/app/pages/subscribe.vue").then(m => m.default || m)
  },
  {
    name: subscribeuWSXqI2j0lMeta?.name ?? "subscribe___de",
    path: subscribeuWSXqI2j0lMeta?.path ?? "/de/subscribe",
    children: [],
    meta: subscribeuWSXqI2j0lMeta,
    alias: subscribeuWSXqI2j0lMeta?.alias || [],
    redirect: subscribeuWSXqI2j0lMeta?.redirect || undefined,
    component: () => import("/app/pages/subscribe.vue").then(m => m.default || m)
  },
  {
    name: _91token_93nN8tYCEP3uMeta?.name ?? "unsubscribe-token___en",
    path: _91token_93nN8tYCEP3uMeta?.path ?? "/unsubscribe/:token",
    children: [],
    meta: _91token_93nN8tYCEP3uMeta,
    alias: _91token_93nN8tYCEP3uMeta?.alias || [],
    redirect: _91token_93nN8tYCEP3uMeta?.redirect || undefined,
    component: () => import("/app/pages/unsubscribe/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93nN8tYCEP3uMeta?.name ?? "unsubscribe-token___de",
    path: _91token_93nN8tYCEP3uMeta?.path ?? "/de/unsubscribe/:token",
    children: [],
    meta: _91token_93nN8tYCEP3uMeta,
    alias: _91token_93nN8tYCEP3uMeta?.alias || [],
    redirect: _91token_93nN8tYCEP3uMeta?.redirect || undefined,
    component: () => import("/app/pages/unsubscribe/[token].vue").then(m => m.default || m)
  }
]