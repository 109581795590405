export const localeCodes = ["en","de"]

export const localeMessages = {
}

export const additionalMessages = Object({"de":[],})

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  const vueI18nOptionsLoader = async (context) => Object({"legacy":false,"locale":"en","messages": Object({"en":{
  "ABOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "ACCELERATEANDSECUREYOURECOMMERCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accelerate and secure your e-commerce project with easy to use"])},
  "ACCESSTOOPENSOURCEMODULESANDINTEGRATEFAST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to open source modules and integrate fast"])},
  "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "ALLFEATURESINCLUDEDNORESTRICTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All features included, no restrictions"])},
  "ALLPRODUCTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All products"])},
  "ANAMAZINGCUSTOMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An amazing customer experience"])},
  "ANYDEVICEANYTIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any device, any time, lower bounce rates, and more page views"])},
  "ASYOURBUSINESSGROWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As your business grows  - percentage pricing drops!"])},
  "AUTOMATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automated"])},
  "BACKTOHOMEPAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home page"])},
  "BACKTOLOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to login"])},
  "BEGINWITHAFREEMIUMPLANE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin with a freemium plan"])},
  "BRINGYOURONLINESELLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring your online selling to the next level."])},
  "BYPYANGOGBMH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by <span class='text-success-400'>Pyango GmbH</span>"])},
  "CALCULATETHEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate the fee - it's only a percentage of your total income."])},
  "CHECKYOUREMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your email"])},
  "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose"])},
  "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "COLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection"])},
  "COMPANYREGISTRATIONNUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Registration Number"])},
  "CONNECTWITHASTOREFRONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect with a storefront"])},
  "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "CONTACTUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "CONTACTUSCONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for contacting us, we will reach back to you in a short time."])},
  "CONTACTUSERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for contacting us, there is a connection problem with the server."])},
  "COOKIEDETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use cookies to personalize content, to provide social media features and to analyze our traffic. We also share information about your use of our site with our social media, advertising, and analytics partners."])},
  "COOKIETITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
  "CREATEASHOP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a shop"])},
  "CREATEASHOPINSERVERALMINUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='font-bold'>Create a shop</span> in several minutes without back-end coding."])},
  "CREATENEWWAREHOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new warehouse"])},
  "DASHBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "DEVELOPERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developers"])},
  "DONOTHAVEANACOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don’t have an account?"])},
  "DOYOUWANTTOTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to try it yourself?"])},
  "EASYTOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy to use"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "EMAILMEARECOVERYLINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email me a recovery link"])},
  "ENGLISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "FAQS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "FASTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faster"])},
  "FEEACCORDINGTO": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fee according to $", _interpolate(_named("value")), "k revenue"])},
  "FILLDEMOSHOPWITHEXAMPLEDATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill demo shop with example data"])},
  "FORGETPASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
  "FORGETPASSWORDSIMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
  "FREE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
  "FREETO10K": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free up to $10K"])},
  "FREETOSTART": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free to start!"])},
  "FREETOUSEUNTIL10KTOTALREVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free to use until $10k total revenue"])},
  "FROMDEVELOPERFORDEVELOPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From developers for developers"])},
  "FULLACCESSTOSHOPCIRCUITPLATFORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full access to ShopCircuit platform"])},
  "FULLNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
  "FUTUREIMPROVEMENTSAREAUTOMATEDANDINSTANTLYAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future improvements are automated and instantly available"])},
  "GERMAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
  "GETYOURFREEMIUMPLAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get your 10K freemium plan today"])},
  "GOTIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it"])},
  "HIGHPERFORMANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High performance"])},
  "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "IAMCREATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am creating/maintaining a shop."])},
  "IAMSHOPOWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am a shop owner"])},
  "IFTHEREISASHOPCIRCUITACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is a ShopCircuit account associated with this email address, we'll send you an email with instructions to reset your password."])},
  "IFYOUARENOTADEVELOPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are not a developer we can do it for you!"])},
  "IFYOUWANTTOSAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to save time, organize better & sell more."])},
  "IMPRINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
  "INTEGRATESHOPCIRCUIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='font-bold'>Integrate</span> ShopCircuit with any website storefront you already have. "])},
  "INVALIDINPUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Input"])},
  "ITISFREEUPTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It’s free up to $10k revenue"])},
  "KNOWHOWMUCHYOUWILLPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Know how much you'll pay"])},
  "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "LESSEFFORTANDMANAGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less effort and management time spent"])},
  "LETSGETSTARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s get started"])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "MADEWITH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Made with "])},
  "MANAGECONTENTEFFORTLESSLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage content effortlessly"])},
  "MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
  "MANAGEORDERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage orders"])},
  "MANAGEYOURPRODUCTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your products"])},
  "MEETOURTEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet our team"])},
  "NONEEDFORADDITIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No need for additional integrations."])},
  "NOTADEVELOPERNOPROBLEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='bg-grad'>Not a developer?</span> No problem we can help."])},
  "NUXTMODULEISOFFERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='font-bold'>Nuxt module</span> is offered for you to install to get started with the storefront implementation for desktop and mobile browser apps."])},
  "OFFERECOMMERCESOLUTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer e-commerce solutions to your clients."])},
  "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "ONEHEADLESSSOFTWARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One headless software"])},
  "OOPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
  "OPENFORNEWTECHNICAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open for new technical solutions."])},
  "OPTIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(optional)"])},
  "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other"])},
  "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "PAYASYOUSELL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay as you sell"])},
  "PERCENTFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percent fee"])},
  "PLEASETAPTHEPLAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please tap the play button to check the result"])},
  "PRESENTTHECONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Present the content"])},
  "PRICING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
  "PRIVACYPOLICY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "PROFESSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession?"])},
  "PROGRESSIVEWEBAPPLICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progressive web application"])},
  "PYANGOGBMH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2021 Pyango GmbH"])},
  "REACTJSVUEJS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["React.JS, Vue.JS, Ionic or mobile native technology. <span class='font-bold'>Pick whatever you like, we will support you.</span>"])},
  "RELOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
  "REQUIREDFIELD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requiered Field"])},
  "REVENUEPERYEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenue per year"])},
  "RICHINHIGHEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rich in high-end features"])},
  "RUNINYOURSELF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run it yourself"])},
  "SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safe"])},
  "SAYMORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Say more..."])},
  "SCISFREETOSETUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ShopCircuit is free to set up and start. Only when you sell products do we charge you a percentage on each order total.<br />This way you can use <span class='font-medium'>ShopCircuit for free</span> until you actually make money."])},
  "SCISTHEONLYECOMMERCEPLATFORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ShopCircuit is the only e-commerce platform you'll ever need to manage all your commerce online. With us, you have the freedom to <span class='if-not-bold-text'>build whatever you want, and how you want it.</span> Separate the backend from the frontend and forget about required updates that can cause you headaches. Finally concentrate on customer experience, marketing, and sales."])},
  "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "SELECTANDCREATEWAREHOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select and create warehouse"])},
  "SELECTYOURREVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your revenue"])},
  "SELLGLOBALLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell globally"])},
  "SELLONMULTIPLECHANNELS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell on multiple channels and reduce e-commerce development costs."])},
  "SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "SETANEWPASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set a new password and you are all ready to go!"])},
  "SETNEWPASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new password"])},
  "SETUPMULTIPLESHOPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up multiple shops"])},
  "SETUPYOURPRODUCTSEOCONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up your products, SEO content, and shipping from a single page in minutes. Automate daily shop operations with an optimized platform that saves your time along with the number of clicks you have to make to finish any process."])},
  "SHOPCIRCUITISASASS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ShopCircuit is a <span class='font-bold'>SaaS</span>. It offers an impeccable backend you'll never have to worry about. Delivers a platform via a <span class='font-bold'>Graphql API</span> that comprises a backend data and a cloud-based infrastructure. This way you can integrate your favourite <span class='font-bold'>CMS, CRM</span>, shipping and any other service to round up your e-commerce project."])},
  "SHOPCIRCUITISFREETO10K": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ShopCircuit is free up to $10K."])},
  "SHOPOWNERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop owners"])},
  "SIGNUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "SIGNUPNOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up now"])},
  "SINGLEBACKENDTOMANAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single backend to manage any number of storefronts, apps and more."])},
  "SITEMAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitemap"])},
  "STARTSELLINGYOURPRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='font-bold'>Start selling</span> your products on multiple platforms."])},
  "STILLYOUCANCREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Still, you can create a webshop without submitting your payment method."])},
  "SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "SUBSCRIBE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
  "SUBSCRIBENOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe now"])},
  "SUBSCRIBENOWANDGETYOURFREEMIUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe now and get your $10k freemium plan"])},
  "SUBTITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtitle...."])},
  "SYSTEMTHATSCALESWITHYOU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A system that scale with you"])},
  "THANKSFORYOURINTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for your interest in our software!"])},
  "THISFIELDISREQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
  "TIMETOACCELERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time to accelerate your business with one trusted backend data center."])},
  "TOSUPPORTYOYRENTIRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To support your <span class='bg-grad'>entire e-commerce business.</span>"])},
  "TRANSPARENTANDSIMPLEPRICING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparent and simple pricing for any business of any size."])},
  "TRYIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it"])},
  "TRYITNOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it now"])},
  "TRYITNOWANDGETYOURFREEMIUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it now and get your 10k freemium plan today"])},
  "TRYITOUTANDSEEHOWFASTIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it out and see how fast it is to set up an online shop with ShopCircuit."])},
  "UNLIMITEDTECHNICALSUPPORTNOSETUPFEESORHIDDENCOSTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited technical support, no setup fees or hidden costs"])},
  "USEANYFREAMWORK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use any framework you desire and customize your store to sell products the way customers will enjoy them. Concentrate on users engagement and sales channels, because in the back everything is working seamlessly."])},
  "USEOURFLEXIBLEGRAPHQL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use our <span class='font-bold'>flexible GraphQL APIs</span> to connect to our system with any client and show the content the way you want."])},
  "WAITMORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait more"])},
  "WAREHOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])},
  "WEAREDEVELOPERSPRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are developers, product designers, and e-commerce experts. Our goal is to transform the e-commerce business from a complicated and extensive business into a simple, fast, and affordable business for everyone."])},
  "WEARENOWTESTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are now testing ShopCircuit public beta with our clients and we're on our way to create an outstanding <span class='lg:font-bold'>e-commerce platform to help many businesses transform and grow fast.</span>"])},
  "WEARESORRYAPPARENTRL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry, apparently, it’s taking a bit longer"])},
  "WEAREWORKINGONYOURREQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are working on your request it might take several seconds!"])},
  "WEBAGENCIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web agencies "])},
  "WEBELIEVEHEADLESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe headless e-commerce platform is the future of selling online. Because it <span class='font-bold'>SAVES PRECIOUS TIME</span> and enables <span class='font-bold'>faster loading pages, higher Google rankings, more search traffic, improved mobile conversions, reduced bounce rates, and better social sharing.</span> Need more?"])},
  "WECANNOTFINDTHEPAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can’t find the page you are looking for."])},
  "WELCOMEBACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back!"])},
  "WELCOMETOSHOPCIRCUIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to ShopCircuit"])},
  "WELOVETOHEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We look forward to hear from you"])},
  "WEPROMISEITISQUICK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We promise it's quick and easy."])},
  "WETAKEPERFORMANCESERIOUSLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='font-bold'>We take performance seriously.</span> Reducing the number of steps you have to do while managing a store, will impact your business in the long run. That's why we created a platform where a simple click replaces series of repetitive actions."])},
  "WEWILLCHARGEASMALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will charge a small percentage of your total revenue"])},
  "WEWILLKEEPYOUUPDATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will keep you updated."])},
  "WHATAREYOUSELLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you selling?"])},
  "WHATCANYOUDO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What can you do with ShopCircuit?"])},
  "WHATDOYOUGET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do you get?"])},
  "WHATWEDO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What we do?"])},
  "WHATWILLITCOST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What will it cost?"])},
  "WHOAREYOU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who are you?"])},
  "WHODOIGETSTARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do I get started?"])},
  "WHOISITFOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who is it for?"])},
  "WHOWEARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who we are?"])},
  "WHYSHOPCIRCUIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why ShopCircuit?"])},
  "WITHACCESSTOTHESOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With access to the source, you are always free to make changes and expand your market."])},
  "WITHOUTTHENEEDTOLEAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without the need to leave your payment method or detailed info just yet!"])},
  "WITHSCYOURCONTENTISACCESSIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With SC your content is accessible through GraphQL and displayable on any device."])},
  "YOUALREADYHAVEANACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have an account"])},
  "YOURMESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message"])},
  "YOURSTOREISNEVERDOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your store is never down or unstable"])},
  "ZERODOWNTIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zero downtime"])},
  "DONTHOLDYOURBRATH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't hold your breath, tell us what is on your mind!"])},
  "WHATAREYOUREMAILPREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are your email preferences?"])},
  "WEPROMISENOTTOCLUTTERYOURINBOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We promise not to clutter your inbox."])},
  "STAYCONNECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay connected"])},
  "IFUHAVESECONDTHOUGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have second thoughts, give us one more chance and you won't regret it!"])},
  "KEEPTHEEMAILCOMING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep the emails coming"])},
  "GIMMEABREAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gimme a break..."])},
  "IFYOUHAVEACHANGEOFHEARTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have a change of hearth, you know where to find us!"])},
  "UNSUBSCRIBE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubscribe"])},
  "WEAREHAPPYTOHAVEYOU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’re happy to have you!"])},
  "WELLKEEPYOUUPDATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’ll keep you updated."])},
  "YOUAREUNSUBSCRIBED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’re unsubscribed"])},
  "YOUWILLNOLONGERRECEIVEEMAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’ll no longer receive emails from us."])},
  "INCASEYOUHAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case you have a moment, please let us know why you opted out."])},
  "INOLONGERWANTTORECEIVEEMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I no longer want to receive emails."])},
  "INEVERSIGNEDUPFORTHISMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I never signed up for this mailing list."])},
  "OTHERFILLTHEREASONINWHY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other (please tell us why)"])},
  "TELLUSWHYYOUAREUNSUBSCRIBING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us why you’re unsubscribing"])},
  "THANKYOUFORYOURFEEDBACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your feedback, we hope to have you back!"])},
  "SUBSCRIBEMEBACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe me back"])},
  "PUBLICBETA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Beta"])},
  "PRESENTCONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Present content"])}
},"de":{
  "ABOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über"])},
  "ACCELERATEANDSECUREYOURECOMMERCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschleunigen und sichern Sie Ihr E-Commerce-Projekt mit der einfach zu bedienenden"])},
  "ACCESSTOOPENSOURCEMODULESANDINTEGRATEFAST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang zu Open-Source-Modulen und schnelle Integration"])},
  "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "ALLFEATURESINCLUDEDNORESTRICTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Funktionen enthalten, keine Einschränkungen"])},
  "ALLPRODUCTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Produkte"])},
  "ANAMAZINGCUSTOMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine erstaunliche Kundenerfahrung"])},
  "ANYDEVICEANYTIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedes Gerät, zu jeder Zeit, geringere Absprungraten und mehr Seitenaufrufe"])},
  "ASYOURBUSINESSGROWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Ihr Unternehmen wächst - die prozentuale Gebühr sinkt!"])},
  "AUTOMATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisiert"])},
  "BACKTOHOMEPAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Hauptseite"])},
  "BACKTOLOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Anmeldung"])},
  "BEGINWITHAFREEMIUMPLANE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginnen Sie mit einem Freemium-Plan"])},
  "BRINGYOURONLINESELLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bringen Sie Ihren Online-Verkauf auf die nächste Stufe."])},
  "BYPYANGOGBMH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von <span class='text-success-400'>Pyango GmbH</span>"])},
  "CALCULATETHEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnen Sie die Gebühr - sie ist nur ein Prozentsatz Ihres Gesamteinkommens."])},
  "CHECKYOUREMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfen Sie Ihre E-Mail"])},
  "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wählen"])},
  "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "COLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammlung"])},
  "COMPANYREGISTRATIONNUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierungsnummer des Unternehmens"])},
  "CONNECTWITHASTOREFRONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinden Sie sich mit einem Schaufenster"])},
  "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "CONTACTUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktiere uns"])},
  "CONTACTUSCONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihre Kontaktaufnahme, wir werden uns in Kürze bei Ihnen melden."])},
  "CONTACTUSERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank, dass Sie sich mit uns in Verbindung gesetzt haben, es gibt ein Verbindungsproblem mit dem Server."])},
  "COOKIEDETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir verwenden Cookies, um Inhalte zu personalisieren, Funktionen für soziale Medien bereitzustellen und unseren Datenverkehr zu analysieren. Wir teilen auch Informationen über Ihre Nutzung unserer Website mit unseren Partnern für soziale Medien, Werbung und Analysen."])},
  "COOKIETITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis"])},
  "CREATEASHOP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Shop erstellen"])},
  "CREATEASHOPINSERVERALMINUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='font-bold'>Einen Shop erstellen</span> in wenigen Minuten ohne Backend-Codierung."])},
  "CREATENEWWAREHOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Lager anlegen"])},
  "DASHBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armaturenbrett"])},
  "DEVELOPERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwickler"])},
  "DONOTHAVEANACOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben noch kein Konto?"])},
  "DOYOUWANTTOTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie es selbst ausprobieren?"])},
  "EASYTOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfach zu bedienen"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "EMAILMEARECOVERYLINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederherstellungslink per E-Mail senden"])},
  "ENGLISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
  "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "FAQS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "FASTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schneller"])},
  "FEEACCORDINGTO": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gebühr nach $", _interpolate(_named("value")), " Umsatz"])},
  "FILLDEMOSHOPWITHEXAMPLEDATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo-Shop mit Beispieldaten befüllen"])},
  "FORGETPASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
  "FREE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlos"])},
  "FREETO10K": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frei bis zu $10K"])},
  "FREETOSTART": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Start ist kostenlos!"])},
  "FREETOUSEUNTIL10KTOTALREVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlose Nutzung bis 10k Gesamtumsatz"])},
  "FROMDEVELOPERFORDEVELOPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von Entwicklern für Entwickler"])},
  "FULLACCESSTOSHOPCIRCUITPLATFORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voller Zugang zur ShopCircuit-Plattform"])},
  "FULLNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständiger Name"])},
  "FUTUREIMPROVEMENTSAREAUTOMATEDANDINSTANTLYAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zukünftige Verbesserungen sind automatisiert und sofort verfügbar"])},
  "GERMAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "GETYOURFREEMIUMPLAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holen Sie sich Ihren 10K Freemium Plan noch heute"])},
  "GOTIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it"])},
  "HIGHPERFORMANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hohe Leistung"])},
  "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
  "IAMCREATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erstelle/betreibe einen Shop."])},
  "IAMSHOPOWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin ein Ladenbesitzer"])},
  "IFTHEREISASHOPCIRCUITACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn mit dieser E-Mail-Adresse ein ShopCircuit-Konto verknüpft ist, senden wir Ihnen eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts."])},
  "IFYOUARENOTADEVELOPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie kein Entwickler sind, können wir es für Sie tun!"])},
  "IFYOUWANTTOSAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie Zeit sparen, besser organisieren und mehr verkaufen wollen."])},
  "IMPRINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
  "INTEGRATESHOPCIRCUIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='font-bold'>Integrieren Sie</span> ShopCircuit mit jeder Website, die Sie bereits haben."])},
  "INVALIDINPUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Eingabe"])},
  "ITISFREEUPTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlos bis zu $10k Umsatz"])},
  "KNOWHOWMUCHYOUWILLPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie wissen, wie viel Sie zahlen werden"])},
  "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "LESSEFFORTANDMANAGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger Aufwand und weniger Zeit für das Management"])},
  "LETSGETSTARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los geht's"])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
  "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmeldung"])},
  "MADEWITH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hergestellt mit "])},
  "MANAGECONTENTEFFORTLESSLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müheloses Verwalten von Inhalten"])},
  "MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung"])},
  "MANAGEORDERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellungen verwalten"])},
  "MANAGEYOURPRODUCTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten Sie Ihre Produkte"])},
  "MEETOURTEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lernen Sie unser Team kennen"])},
  "NONEEDFORADDITIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Notwendigkeit für zusätzliche Integrationen."])},
  "NOTADEVELOPERNOPROBLEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='bg-grad'>Sie sind kein Entwickler?</span> Kein Problem, wir können helfen!"])},
  "NUXTMODULEISOFFERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das <span class='font-bold'>Nuxt-Modul</span> wird Ihnen zur Installation angeboten, um mit der Storefront-Implementierung für Desktop- und mobile Browser-Anwendungen zu beginnen."])},
  "OFFERECOMMERCESOLUTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieten Sie Ihren Kunden E-Commerce-Lösungen an."])},
  "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "ONEHEADLESSSOFTWARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine kopflose Software"])},
  "OOPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups!"])},
  "OPENFORNEWTECHNICAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen für neue technische Lösungen."])},
  "ORGETPASSWORDSIMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
  "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["andere"])},
  "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort"])},
  "PAYASYOUSELL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlen Sie beim Verkauf"])},
  "PERCENTFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozentuale Gebühr"])},
  "PLEASETAPTHEPLAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte tippen Sie auf die Play-Taste, um das Ergebnis zu überprüfen"])},
  "PRESENTTHECONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Präsentieren Sie den Inhalt"])},
  "PRICING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisgestaltung"])},
  "PRIVACYPOLICY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
  "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
  "PROFESSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beruf?"])},
  "PROGRESSIVEWEBAPPLICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschrittliche Webanwendung"])},
  "PYANGOGBMH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2021 Pyango GmbH"])},
  "REACTJSVUEJS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["React.JS, Vue.JS, Ionic oder mobile native Technologie. <span class='font-bold'>Wählen Sie, was Sie wollen, wir unterstützen Sie.</span>"])},
  "RELOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neu laden"])},
  "REQUIREDFIELD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewünschtes Feld"])},
  "REVENUEPERYEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsatz pro Jahr"])},
  "RICHINHIGHEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reich an High-End-Funktionen"])},
  "RUNINYOURSELF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie es selbst"])},
  "SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicher"])},
  "SAYMORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sagen Sie mehr..."])},
  "SCISFREETOSETUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ShopCircuit kann kostenlos eingerichtet und gestartet werden. Erst wenn Sie Produkte verkaufen, berechnen wir Ihnen einen Prozentsatz der Bestellsumme.<br />Auf diese Weise können Sie <span class='font-medium'>ShopCircuit kostenlos</span> nutzen, bis Sie tatsächlich Geld verdienen."])},
  "SCISTHEONLYECOMMERCEPLATFORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ShopCircuit ist die einzige E-Commerce-Plattform, die Sie jemals brauchen werden, um Ihren gesamten Online-Handel zu verwalten. Mit uns haben Sie die Freiheit, <span class='if-not-bold-text'>zu bauen, was immer Sie wollen und wie Sie es wollen.</span> Trennen Sie das Backend vom Frontend und vergessen Sie die erforderlichen Updates, die Ihnen Kopfschmerzen bereiten können. Konzentrieren Sie sich endlich auf das Kundenerlebnis, das Marketing und den Verkauf."])},
  "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
  "SELECTANDCREATEWAREHOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lager auswählen und anlegen"])},
  "SELECTYOURREVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihr Einkommen"])},
  "SELLGLOBALLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global verkaufen"])},
  "SELLONMULTIPLECHANNELS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufen Sie auf mehreren Kanälen und reduzieren Sie die Entwicklungskosten für E-Commerce."])},
  "SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
  "SETANEWPASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legen Sie ein neues Passwort fest, und schon können Sie loslegen!"])},
  "SETNEWPASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort festlegen"])},
  "SETUPMULTIPLESHOPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrere Shops einrichten"])},
  "SETUPYOURPRODUCTSEOCONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richten Sie Ihre Produkte, SEO-Inhalte und den Versand von einer einzigen Seite aus in wenigen Minuten ein. Automatisieren Sie die täglichen Abläufe in Ihrem Shop mit einer optimierten Plattform, die Ihnen Zeit spart und die Anzahl der Klicks reduziert, die Sie zum Abschluss eines Prozesses machen müssen."])},
  "SHOPCIRCUITISASASS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ShopCircuit ist ein <span class='font-bold'>SaaS</span>. Es bietet ein tadelloses Backend, um das Sie sich nie kümmern müssen. Die Plattform wird über eine <span class='font-bold'>Graphql API</span> bereitgestellt, die Backend-Daten und eine Cloud-basierte Infrastruktur umfasst. Auf diese Weise können Sie Ihr bevorzugtes <span class='font-bold'>CMS, CRM</span>, Versand und jeden anderen Dienst integrieren, um Ihr E-Commerce-Projekt abzurunden."])},
  "SHOPCIRCUITISFREETO10K": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ShopCircuit ist bis zu $10K kostenlos."])},
  "SHOPOWNERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop-Betreiber"])},
  "SIGNUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "SIGNUPNOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt anmelden"])},
  "SINGLEBACKENDTOMANAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein einziges Backend für die Verwaltung einer beliebigen Anzahl von Storefronts, Apps und mehr."])},
  "SITEMAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitemap"])},
  "STARTSELLINGYOURPRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='font-bold'>Mit dem Verkauf beginnen</span> Ihre Produkte auf mehreren Plattformen."])},
  "STILLYOUCANCREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können jedoch einen Webshop erstellen, ohne Ihre Zahlungsmethode anzugeben."])},
  "SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreichen"])},
  "SUBSCRIBENOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt abonnieren"])},
  "SUBSCRIBENOWANDGETYOURFREEMIUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnieren Sie jetzt und erhalten Sie Ihren $10k Freemium-Plan"])},
  "SUBTITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untertitel...."])},
  "SYSTEMTHATSCALESWITHYOU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein System, das mit Ihnen skaliert"])},
  "THANKSFORYOURINTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihr Interesse an unserer Software!"])},
  "THISFIELDISREQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld ist erforderlich"])},
  "TOSUPPORTYOYRENTIRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Unterstützung Ihres <span class='bg-grad'>gesamten E-Commerce-Geschäfts.</span>"])},
  "TRANSPARENTANDSIMPLEPRICING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparente und einfache Preisgestaltung für jedes Unternehmen jeder Größe."])},
  "TRYIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["versuchen Sie es"])},
  "TRYITNOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testen Sie es jetzt"])},
  "TRYITNOWANDGETYOURFREEMIUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testen Sie es jetzt und holen Sie sich noch heute Ihren 10k Freemium-Plan"])},
  "TRYITOUTANDSEEHOWFASTIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probieren Sie es aus und sehen Sie, wie schnell Sie mit ShopCircuit einen Online-Shop einrichten können."])},
  "UNLIMITEDTECHNICALSUPPORTNOSETUPFEESORHIDDENCOSTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbegrenzter technischer Support, keine Einrichtungsgebühren oder versteckten Kosten"])},
  "USEANYFREAMWORK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzen Sie jedes beliebige Framework und passen Sie Ihren Shop so an, dass er Produkte so verkauft, wie es den Kunden gefällt. Konzentrieren Sie sich auf das Engagement der Nutzer und die Verkaufskanäle, denn im Hintergrund funktioniert alles reibungslos."])},
  "USEOURFLEXIBLEGRAPHQL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzen Sie unsere <span class='font-bold'>flexiblen GraphQL APIs</span>, um sich mit jedem beliebigen Client mit unserem System zu verbinden und die Inhalte so anzuzeigen, wie Sie es wünschen."])},
  "WAITMORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr warten"])},
  "WAREHOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagerhaus"])},
  "WEAREDEVELOPERSPRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind Entwickler, Produktdesigner und E-Commerce-Experten. Unser Ziel ist es, das E-Commerce-Geschäft von einem komplizierten und umfangreichen Geschäft in ein einfaches, schnelles und erschwingliches Geschäft für jedermann zu verwandeln."])},
  "WEARENOWTESTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir testen ShopCircuit jetzt in der öffentlichen Beta-Phase mit unseren Kunden und sind auf dem Weg, eine hervorragende <span class='lg:font-bold'>E-Commerce-Plattform zu schaffen, die vielen Unternehmen hilft, sich zu transformieren und schnell zu wachsen.</span>"])},
  "WEARESORRYAPPARENTRL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es tut uns leid, anscheinend dauert es ein bisschen länger"])},
  "WEAREWORKINGONYOURREQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir arbeiten an Ihrer Anfrage, es kann einige Sekunden dauern!"])},
  "WEBAGENCIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web-Agenturen "])},
  "WEBELIEVEHEADLESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir glauben, dass die Headless-E-Commerce-Plattform die Zukunft des Online-Verkaufs ist. Denn sie <span class='font-bold'>spart wertvolle Zeit</span> und ermöglicht <span class='font-bold'>schnellere Ladeseiten, höhere Google-Rankings, mehr Suchverkehr, verbesserte mobile Konversionen, geringere Absprungraten und besseres Social Sharing.</span> Brauchen Sie mehr?"])},
  "WEBELIEVEINHEADLESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir glauben, dass die Headless E-Commerce-Plattform die Zukunft des Online-Verkaufs ist. Denn sie <span class='font-bold'>spart wertvolle Zeit </span> und ermöglicht <span class='font-bold'> schnelleres Laden der Seiten, höhere Google-Rankings, mehr Suchverkehr, bessere mobile Konversionen, geringere Absprungraten und besseres Social Sharing.</span> Brauchen Sie mehr?"])},
  "WECANNOTFINDTHEPAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir können die von Ihnen gesuchte Seite nicht finden."])},
  "WELCOMEBACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen zurück!"])},
  "WELCOMETOSHOPCIRCUIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen bei ShopCircuit"])},
  "WELOVETOHEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir freuen uns darauf, von Ihnen zu hören"])},
  "WEPROMISEITISQUICK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir versprechen, dass es schnell und einfach geht."])},
  "WETAKEPERFORMANCESERIOUSLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='font-bold'>Wir nehmen Leistung ernst.</span> Die Verringerung der Anzahl der Schritte, die Sie bei der Verwaltung eines Geschäfts durchführen müssen, wirkt sich langfristig auf Ihr Geschäft aus. Deshalb haben wir eine Plattform geschaffen, bei der ein einfacher Klick eine Reihe von sich wiederholenden Aktionen ersetzt."])},
  "WEWILLCHARGEASMALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir berechnen einen kleinen Prozentsatz Ihres Gesamtumsatzes"])},
  "WEWILLKEEPYOUUPDATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir werden Sie auf dem Laufenden halten."])},
  "WHATAREYOUSELLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was verkaufen Sie?"])},
  "WHATCANYOUDO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was können Sie mit ShopCircuit tun?"])},
  "WHATDOYOUGET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was bekommen Sie?"])},
  "WHATWEDO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was wir tun?"])},
  "WHATWILLITCOST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was wird es kosten?"])},
  "WHOAREYOU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wer sind Sie?"])},
  "WHODOIGETSTARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie fange ich an?"])},
  "WHOISITFOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für wen ist es gedacht?"])},
  "WHOWEARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wer wir sind?"])},
  "WHYSHOPCIRCUIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum ShopCircuit?"])},
  "WITHACCESSTOTHESOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da Sie Zugang zur Quelle haben, können Sie jederzeit Änderungen vornehmen und Ihren Markt erweitern."])},
  "WITHOUTTHENEEDTOLEAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen noch nicht einmal Ihre Zahlungsmethode oder detaillierte Informationen hinterlassen!"])},
  "WITHSCYOURCONTENTISACCESSIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit SC sind Ihre Inhalte über GraphQL zugänglich und können auf jedem Gerät angezeigt werden."])},
  "YOUALREADYHAVEANACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben bereits ein Konto"])},
  "YOURMESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Nachricht"])},
  "YOURSTOREISNEVERDOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Shop ist nie ausgefallen oder instabil"])},
  "ZERODOWNTIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ausfallzeiten"])},
  "DONTHOLDYOURBRATH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halten Sie nicht den Atem an, sagen Sie uns, was Sie auf dem Herzen haben!"])},
  "WHATAREYOUREMAILPREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was sind Ihre E-Mail-Vorlieben?"])},
  "WEPROMISENOTTOCLUTTERYOURINBOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir versprechen, Ihren Posteingang nicht zu überladen."])},
  "STAYCONNECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bleiben Sie in Verbindung"])},
  "IFUHAVESECONDTHOUGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie es sich anders überlegen, geben Sie uns noch eine Chance und Sie werden es nicht bereuen!"])},
  "KEEPTHEEMAILCOMING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behalten Sie die E-Mails bei sich"])},
  "GIMMEABREAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönnen Sie mir eine Pause..."])},
  "IFYOUHAVEACHANGEOFHEARTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie Ihre Meinung ändern möchten, wissen Sie, wo Sie uns finden können!"])},
  "UNSUBSCRIBE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbestellen"])},
  "WEAREHAPPYTOHAVEYOU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind froh, dass Sie da sind!"])},
  "WELLKEEPYOUUPDATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir werden Sie auf dem Laufenden halten."])},
  "YOUAREUNSUBSCRIBED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind abgemeldet"])},
  "YOUWILLNOLONGERRECEIVEEMAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie werden keine E-Mails mehr von uns erhalten."])},
  "INCASEYOUHAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falls Sie einen Moment Zeit haben, teilen Sie uns bitte mit, warum Sie sich abgemeldet haben."])},
  "INOLONGERWANTTORECEIVEEMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte keine E-Mails mehr erhalten."])},
  "INEVERSIGNEDUPFORTHISMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe mich nie für diese Mailingliste angemeldet."])},
  "OTHERFILLTHEREASONINWHY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges (geben Sie den Grund dafür an)"])},
  "TELLUSWHYYOUAREUNSUBSCRIBING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sagen Sie uns, warum Sie sich abmelden"])},
  "THANKYOUFORYOURFEEDBACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihr Feedback, wir hoffen, Sie wieder bei uns begrüßen zu dürfen!"])},
  "SUBSCRIBEMEBACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnieren Sie mich zurück"])}
},}),})
  nuxtI18nOptions.vueI18n = await vueI18nOptionsLoader(context)
  nuxtI18nOptions.locales = ["en","de"]
  nuxtI18nOptions.defaultLocale = "en"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "prefix_except_default"
  nuxtI18nOptions.lazy = false
  nuxtI18nOptions.langDir = null
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true})
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = ""
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "page"
  nuxtI18nOptions.pages = Object({})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.onBeforeLanguageSwitch = (() => "")
  nuxtI18nOptions.onLanguageSwitched = (() => null)
  nuxtI18nOptions.types = undefined
  nuxtI18nOptions.debug = false
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({vueI18n: undefined,locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,onBeforeLanguageSwitch: (() => ""),onLanguageSwitched: (() => null),types: undefined,debug: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"en","iso":"en"}),Object({"code":"de","iso":"de"})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = false
export const isSSR = true
