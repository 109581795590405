import { setupWorker } from "msw";
import { handlers } from "../mocks/handlers";
import { handlers as handlersCypress } from "../cypress/mocks/handlers";

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();
  try {
    if (config.public.enableMockserver === "true") {
      const worker =
        config.public.enableCypress === "false"
          ? setupWorker(...handlers)
          : setupWorker(...handlersCypress);
      await worker.start({
        onUnhandledRequest(req) {
          if (req.url.pathname.startsWith("/_nuxt")) {
            return;
          }
          console.error(
            "Found an unhandled %s request to %s",
            req.method,
            req.url.href
          );
        },
      });
      console.log("mockserver started");
    }
  } catch (error) {
    console.log(error);
  }
});
