import { useCommon } from "@/store";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("apollo:error", (error) => {
    error.graphQLErrors.forEach((e) => {
      if (e.non_field_errors) {
        useCommon().pushNotifications({
          type: "error",
          message: e.non_field_errors[0],
        });
      } else if (e.field_errors) {
        Object.keys(e.field_errors).forEach((fieldName) => {
          e.field_errors[fieldName].forEach((item) => {
            useCommon().pushNotifications({
              type: "error",
              message: item,
            });
          });
        });
      } else {
        useCommon().pushNotifications({
          type: "error",
          message: e.message,
        });
      }
    });
  });
});
