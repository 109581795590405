export const addBaseUrl = (path) => {
  return new URL(path, `https://backend.helios.pyango.ch/api/`).toString();
};

export const makeResponse = ({
  res,
  ctx,
  status = 200,
  delay = 0,
  json = {},
}) => {
  return res(ctx.status(status), ctx.delay(delay), ctx.json(json));
};
