import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin(async (nuxtApp) => {
  const { vueApp } = nuxtApp;
  const config = useRuntimeConfig();
  
  Sentry.init({
    app: [vueApp],
    dsn: config.public.sentryDsn,
    environment: config.public.nodeEnv,
    release: config.public.sentryRelease,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(
          nuxtApp.$router
        ),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      })
    ],
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        console.error(
          `[Exeption handled by Sentry]: (${hint.originalException})`,
          { event, hint }
        );
      }
      // Continue sending to Sentry
      return event;
    },
  });

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ["activate", "mount", "update"],
    })
  );
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ["activate", "mount", "update"],
  });

  return {
    provide: {
      sentryCaptureException: Sentry.captureException,
    },
  };
});
