import { useCommon } from "@/store";

export default defineNuxtPlugin(async () => {
  try {
    const config = useRuntimeConfig();
    const { UnleashClient } = await import("unleash-proxy-client");

    const unleash = new UnleashClient({
      url: config.public.unleashUrl + "/proxy",
      clientKey: config.public.unleashProxyKey,
      appName: config.public.unleashAppName, // Set to the running environment of your application
      refreshInterval: 10,
    });

    unleash.on("update", () => {
      const featureFlags = {};
      unleash.toggles.forEach((item) => {
        featureFlags[item.name] = item.enabled;
      });
      useCommon().setAllFeatures(featureFlags);
    });

    await unleash.start();
  } catch (error) {
    console.log(error);
  }
});
