import DOMPurify from "isomorphic-dompurify";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("sanitize-html", {
    mounted(el, binding) {
      try {
        el.innerHTML = binding.value ? DOMPurify.sanitize(binding.value) : "";
      } catch (error) {}
    },
  });
});
